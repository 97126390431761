import React from 'react';
import './App.css'; // make sure to import your css

function App() {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-r from-gray-700 via-gray-900 to-black text-white">
      <div className="p-10 bg-white bg-opacity-5 rounded-xl shadow-2xl backdrop-blur-lg backdrop-brightness-75">
        <h1 className="text-4xl mb-4 mx-auto font-orbitron text-center">shasys</h1>
        <p className="font-orbitron text-center mb-4">decentralized VPN</p>
        <div className="flex justify-center">
          <a
            href="https://forms.gle/mNnn1JR2wJWod2vF7"
            target="_blank"
            rel="noopener noreferrer"
            className="px-4 py-2 font-orbitron font-bold text-white bg-gradient-to-r from-blue-500 to-purple-500 hover:from-purple-500 hover:to-blue-500 rounded shadow-lg transition-all duration-500"
          >
            Join the waitlist
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
